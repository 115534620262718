<template>
  <div>正確{{member}}</div>
  <div>{{pageToken}}</div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      // pageToken: "",
    };
  },
  methods: {
    getCookie() {
      this.$store.dispatch("getCookie");
    },
    getMember() {
      this.$store.dispatch("getMember");
    },
  },
  computed: {
    member() {
      return this.$store.state.TWDDMember;
    },
    pageToken() {
      return this.$store.state.pageToken;
    },
  },

  mounted() {
    this.getCookie();
    this.getMember();
  },
};
</script> 